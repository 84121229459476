<template>
  <div class="data-monitor-sales">
    <Header />
    <Tabs class="tabs">
      <Tab title="产品工艺分析">
        <Table title="工艺" />
      </Tab>
      <Tab title="产品含量销量分析">
        <Table title="含量" />
      </Tab>
    </Tabs>

  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';
import Table from './table';
import Header from '../../../components/header';
export default {
  components: {
    Tab,
    Tabs,
    Table,
    Header,
  }
}
</script>

<style lang="scss" scoped>
.data-monitor-sales {
  min-height: 100vh;
  background-color: #FAFBFC;
  ::v-deep .van-tabs__wrap {
    padding: 12px 32px;
    background-color: #fff;
    margin-bottom: 10px;
  }
  ::v-deep .van-tabs__nav {
    background-color: transparent !important;
  }
  ::v-deep .van-tab {
    flex: 1 !important;
    margin-right: 0;
  }
}
</style>